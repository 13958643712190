import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import { useAuthContext } from '../../auth/useAuthContext';
import { getCommandById } from '../../redux/slices/command/commandThunk';
import { confirmStep, removeUpdate, setTreatment } from '../../redux/slices/prothese/protheseSlice';
import { createProthese, createTraitements, getProtheseById, updateProthese, updateTraitements, } from '../../redux/slices/prothese/protheseThunk';
import { getTreatmentById } from '../../redux/slices/treatment/treatmentsThunk';
import { dispatch, useSelector } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
import PatientNewForm from '../../sections/@dashboard/patient/PatientNewForm';
import PatientSecondStep from '../../sections/@dashboard/patient/PatientSecondStep';
import PatientThirdStep from '../../sections/@dashboard/patient/PatientThirdStep';
import ConfirmDialog from '../confirm-dialog';
import StepperPopup from './StepperPopup/StepperPopup';
// ----------------------------------------------------------------------
const steps = ['Création carte patient', 'Création prothèse', 'Création d’un ticket'];
export default function LinearAlternativeLabel() {
    const [currentId, setCurrentId] = useState('');
    const navigate = useNavigate();
    const { step, id, treatmentId } = useParams();
    const stepNumber = step ? parseInt(step, 10) : undefined;
    const [selectedDent, setSelectedDent] = useState(0);
    const level = stepNumber !== undefined ? stepNumber - 1 : undefined;
    const [activeStep, setActiveStep] = useState(level || 0);
    const [skipped, setSkipped] = useState(new Set());
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openConfirm2, setOpenConfirm2] = useState(false);
    const [load, setLoad] = useState(false);
    const _id = useSelector((state) => state.patient.onData?._id);
    useEffect(() => {
        if ((stepNumber !== undefined && (stepNumber < 1 || stepNumber > 3)) ||
            (stepNumber && stepNumber == 2 && !id) ||
            (stepNumber && stepNumber == 3 && !id && !treatmentId)) {
            navigate(PATH_DASHBOARD.patientForm
                .replace(':step', '1')
                .replace('/:id?', '')
                .replace(':treatmentId?', ''));
            setActiveStep(0);
        }
    }, [navigate, stepNumber, id]);
    const isStepSkipped = (step) => skipped.has(step);
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const { enqueueSnackbar } = useSnackbar();
    const data = useSelector((state) => state.dent);
    const error = useSelector((state) => state.patient.error);
    const done = data.done;
    const files = data.files;
    const treatment = useSelector((state) => state.treatment.oneData);
    const update = data.isUpdate;
    const description = data?.description;
    const updateProthses = data.isUpdate2;
    const protheseId = data.ProtheseId;
    const currentTreatment = data.TreatmentId;
    const currentCommand = data.commandId;
    const forwardCommand = data.forwardCommandView;
    const { user } = useAuthContext();
    const laboratory = user?.roles[0]?.code === 'PROTHESIST'
        ? user?.laboratory?._id
        : user?.roles[0]?.code === 'DENTIST'
            ? user?.cabinet?.laboratory
            : undefined;
    const [num, setNum] = useState(data?.digitalFootprint);
    const [phy, setPhy] = useState(data?.physicalFootprint);
    return (_jsxs(_Fragment, { children: [_jsx(Stepper, { activeStep: activeStep, alternativeLabel: true, children: steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (_jsx(Step, { ...stepProps, children: _jsx(StepLabel, { ...labelProps, children: label }) }, label));
                }) }), activeStep === steps.length ? (_jsxs(_Fragment, { children: [_jsx(Paper, { sx: {
                            p: 3,
                            my: 3,
                            minHeight: 120,
                            bgcolor: (theme) => alpha(theme.palette.success.main, 0),
                        }, children: _jsx(Typography, { sx: { my: 1 }, children: "All steps completed - you're finished" }) }), _jsxs(Box, { sx: { display: 'flex' }, children: [_jsx(Box, { sx: { flexGrow: 1 } }), _jsx(Button, { onClick: handleReset, children: "Reset" })] })] })) : (_jsxs(_Fragment, { children: [_jsx(Paper, { sx: {
                            p: 3,
                            my: 3,
                            minHeight: 120,
                            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
                        }, children: activeStep === 0 ? (_jsx(_Fragment, { children: _jsx(PatientNewForm, { setOpenConfirm: setOpenConfirm, setCurrentId: setCurrentId, handleNext: handleNext }) })) : activeStep === 1 ? (_jsxs(_Fragment, { children: [_jsx(PatientSecondStep, { selectedDent: selectedDent, setSelectedDent: setSelectedDent, num: num, setNum: setNum, phy: phy, setPhy: setPhy }), _jsx(Box, { sx: {
                                        width: '100%',
                                        justifyContent: 'end',
                                        display: 'flex',
                                        padding: '30px',
                                        gap: '15px',
                                    }, children: !error && (_jsx(Button, { color: "info", variant: "outlined", onClick: () => {
                                            setOpenConfirm2(true);
                                        }, children: update ? 'Mise à jour' : 'Enregistrer' })) })] })) : (_jsx(_Fragment, { children: _jsx(PatientThirdStep, { setActiveStep: setActiveStep, setSelectedDent: setSelectedDent }) })) }), _jsx(Box, { sx: { display: 'flex' } }), _jsx(StepperPopup, { openConfirm: openConfirm, handleNext: handleNext, setOpenConfirm: setOpenConfirm, step: level ? level + 1 : 1, id: currentId })] })), _jsx(ConfirmDialog, { cancelText: `Annuler`, open: openConfirm2, onClose: () => {
                    setOpenConfirm2(false);
                }, title: "Confirmer", content: "Etes-vous s\u00FBr de vouloir soumettre, seuls les \u00E9l\u00E9ments confirm\u00E9s seront envoy\u00E9s", action: _jsx(LoadingButton, { variant: "contained", loading: load, color: "error", onClick: async () => {
                        if (!done) {
                            if (!update) {
                                const dents = data.dents
                                    .filter((option) => option.confirmed === true)
                                    .map((option) => ({
                                    name: option.name,
                                    material: option.material,
                                    type: option.type,
                                    color: option.color,
                                    remarks: option.remarks,
                                    price: option.price,
                                    ombre: option.ombre,
                                }));
                                const params = {
                                    patient: data?.patient || _id,
                                    dents: dents,
                                    description: description,
                                };
                                setLoad(true);
                                const response = (await dispatch(createTraitements({ params })));
                                if (response?.payload?.statusCode === 200) {
                                    enqueueSnackbar('Traitement creé avec success !');
                                    setLoad(false);
                                    setOpenConfirm2(false);
                                    dispatch(setTreatment(response?.payload?.data?.traitement?._id));
                                    dispatch(getTreatmentById({ id: response?.payload?.data?.traitement?._id }));
                                    dispatch(confirmStep());
                                }
                                else {
                                    enqueueSnackbar(response.payload?.message || "Quelque chose c'est mal passé !", {
                                        variant: 'error',
                                    });
                                    setLoad(false);
                                }
                            }
                            else {
                                const dents = data.dents
                                    .filter((option) => option.confirmed === true)
                                    .map((option) => ({
                                    name: option.name,
                                    material: option.material,
                                    type: option.type,
                                    color: option.color,
                                    remarks: option.remarks,
                                    price: option.price,
                                    ombre: option.ombre,
                                }));
                                const params = {
                                    patient: data?.patient || _id,
                                    dents: dents,
                                    description: description,
                                };
                                setLoad(true);
                                const response = (await dispatch(updateTraitements({ id: currentTreatment || '', params })));
                                setSelectedDent(1);
                                if (response?.payload?.statusCode === 200) {
                                    enqueueSnackbar('Traitement mis à jour avec success !');
                                    setLoad(false);
                                    dispatch(confirmStep());
                                    dispatch(removeUpdate());
                                    if (currentTreatment)
                                        dispatch(getTreatmentById({ id: currentTreatment }));
                                    setOpenConfirm2(false);
                                }
                                else {
                                    enqueueSnackbar(response.payload?.message || "Quelque chose c'est mal passé !", {
                                        variant: 'error',
                                    });
                                    setLoad(false);
                                }
                            }
                        }
                        else {
                            if (!updateProthses) {
                                const params = {
                                    traitement: treatment?._id || '',
                                    files,
                                    laboratory,
                                    digitalFootprint: num,
                                    physicalFootprint: phy,
                                };
                                setLoad(true);
                                const response = (await dispatch(createProthese({ params })));
                                if (response?.payload?.statusCode === 200) {
                                    enqueueSnackbar('Prothèse creé avec success !');
                                    setOpenConfirm2(false);
                                    const data = (await dispatch(getProtheseById({ id: response?.payload?.data?._id })));
                                    setLoad(false);
                                    if (step && id) {
                                        navigate(PATH_DASHBOARD.patientForm
                                            .replace(':step', (3).toString())
                                            .replace(':id?', id)
                                            .replace(':treatmentId?', data?.payload?.data?.data?._id || ''));
                                    }
                                    handleNext();
                                }
                                else {
                                    enqueueSnackbar(response.payload?.message || "Quelque chose c'est mal passé !", {
                                        variant: 'error',
                                    });
                                }
                                setLoad(false);
                            }
                            else if (protheseId) {
                                const params = {
                                    traitement: treatment?._id || '',
                                    files,
                                    digitalFootprint: num,
                                    physicalFootprint: phy,
                                };
                                setLoad(true);
                                const response = (await dispatch(updateProthese({ id: protheseId, params })));
                                if (response?.payload?.statusCode === 200) {
                                    enqueueSnackbar('Commande mis à jour avec success !');
                                    setOpenConfirm2(false);
                                    const data = (await dispatch(getProtheseById({ id: protheseId })));
                                    setLoad(false);
                                    if (forwardCommand && currentCommand) {
                                        if (currentCommand)
                                            dispatch(getCommandById({ id: currentCommand }));
                                        navigate(PATH_DASHBOARD.order.replace(':id', currentCommand));
                                    }
                                    else if (step && id) {
                                        navigate(PATH_DASHBOARD.patientForm
                                            .replace(':step', (3).toString())
                                            .replace(':id?', id)
                                            .replace(':treatmentId?', data?.payload?.data?.data?._id || ''));
                                    }
                                    handleNext();
                                }
                                else {
                                    enqueueSnackbar(response.payload?.message || "Quelque chose c'est mal passé !", {
                                        variant: 'error',
                                    });
                                }
                                setLoad(false);
                            }
                        }
                    }, children: "Confirmer" }) })] }));
}
